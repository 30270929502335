import React from "react";
import Img from "../hacks/GatsbyImage";

export default props => {
  console.log(props.src.childImageSharp.fluid.presentationWidth);

  let normalizedProps = {
    fluid: props.src.childImageSharp.fluid,
    ...props,
    style: {
      maxWidth: props.src.childImageSharp.fluid.presentationWidth,
      width: props.src.childImageSharp.fluid.presentationWidth + "px",
      height: props.src.childImageSharp.fluid.presentationHeight + "px",
      margin: "30px auto", // Used to center the image
      ...(props.style || {}),
    },
  };

  console.log(normalizedProps);

  return <Img {...normalizedProps} />;
  return (
    <Img
      fluid={props.src.childImageSharp.fluid}
      width={props.src.childImageSharp.fluid.presentationWidth + "px"}
      height={props.src.childImageSharp.fluid.presentationHeight + "px"}
      style={{
        maxWidth: props.src.childImageSharp.fluid.presentationWidth,
        margin: "30px auto",
        borderRadius: "160px",
      }}
    />
  );
};
