import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import style from "../styles/post.module.css";

export default props => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: { relativePath: { regex: "/hello.jpg/" } }) {
        totalCount
        edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  return (
    <header>
      <h1>
        {props.src}
        {data.site.siteMetadata.title}
      </h1>
    </header>
  );
};
