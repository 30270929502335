import React from "react";

import style from "../styles/newsletter.module.css";

export const Newsletter = ({
  nextPath,
  previousPath,
  nextLabel,
  previousLabel,
}) => (
  <div className={style.newsletter}>
    <form
      className={style.mailjetForm}
      action="https://app.mailjet.com/widget/iframe/4b0H/iH5"
      name="form"
      method="post"
    >
      <input
        type="hidden"
        id="csrf_token"
        name="csrf_token"
        value="OGY5ZGJkYmI2YzNjODhhYjQ0YWY5MTcyYzU3YzQ1OTY3YTA4YjkzYjMzODFlMDcyZWI4YTk0YTQwNDgxMDkzNw=="
      />
      <div className={style.title}>Join "Elite Club of My Subscribers"!</div>

      <div className={style.signupContainer}>
        <input
          className={style.email}
          type="email"
          placeholder="Your email..."
          name="w-field-field-71863-403839-995639-email"
          required="required"
        />

        <input className={style.submit} type="submit" value="Join now!" />
      </div>

      <div className={style.subtext}>
        No spam ever! And you can unsubscribe easily anytime you want.
      </div>
    </form>
  </div>
);

Newsletter.propTypes = {};

export default Newsletter;
