module.exports = [{
      plugin: require('/opt/build/repo/blog/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embed-video","options":{"related":false,"noIframeBorder":true}},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"quality":75}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":false,"noInlineHighlight":false}}]},
    },{
      plugin: require('/opt/build/repo/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140524072-1"},
    },{
      plugin: require('/opt/build/repo/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
